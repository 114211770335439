import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    {/*<a*/}
    {/*  href='https://github.com/rajshekhar26/cleanfolio'*/}
    {/*  className='link footer__link'*/}
    {/*>*/}
    {/*  Created By Raj Shekhar*/}
    {/*</a>*/}
  </footer>
)

export default Footer
